.nav {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    height: 3rem;
    z-index: 1;
}

.align-center{
    align-items: center;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 1s;
}

.navColor {
    background-color: rgb(24 27 37);
    transition: all 0.5s;
    opacity: 1;
}

.hidden {
    opacity: 0;
    transition: all 0.5s;
    pointer-events: none;
}

.items{
    display: flex;
    align-items: center;
    padding: 20px;
    transition: all 0.3s;
    height: 100%;
}

ul[class = 'sticky nav align-center'] .items:hover {
    scale: 1.2;
}

.burger {
    display: none;
}


@media only screen and (max-width: 600px) {
    ul[class = 'sticky nav align-center'] >.items {
        opacity: 0;
        pointer-events: none;
        width: 100%;
        justify-content: flex-end;
        margin: -10px;

    }

    .items {
        transition: all 0s;
    }

    .show {
        display: flex;
        opacity: 1;
        background-color: #242526;
        height: 100%;
        padding: 20px;
        width: 100%;
        border-color: #242526;
        margin: -5px;
        justify-content: flex-end;
        align-items: center;
    }

    .burger {
        display: inline;
        padding: 0px;
        transition: 0.3s;
        cursor: pointer;
        height: 100%;
        padding-right: 2rem;
    }

    .burger-container {
        width: 40px;
        height: 100%;
    }

    .block{
        display: block;
        transition: 0.3s;
    }

    .block:hover{
        scale: 1.2;
        cursor: pointer;
        transition: 0.3s;
    }

    .burgerImage {
        height: 100%;
        width: 40px;
    }

    .nav {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-end;
    }

    ul.sticky {
        top: 60px;
        width: 100%;
    }

  }