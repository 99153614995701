
.contact-container {
    margin: auto;
    width: 100%;
}

.btn-calltoaction {
    border: 2px solid white;
    padding: 10px;
    margin: 2rem;
}

.cta-text {
    padding: 2rem;
    font-size: large;
}

a {
    text-decoration: none;
    color: white;
}

.contactbar{
    display: block;
}

.footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgb(24 27 37);
}

.items {
    text-decoration: none;
    width: 5rem;
    height: 5rem;
}

.footer.items img{
    transition: all 0.3s;
}

.footer.items img:hover{
    cursor: pointer;
    transition: all 0.3s;
    scale: 1.3;
}

ul {
    margin: 0; /* To remove default bottom margin */ 
    padding: 0rem;
}

li {
    padding: 2rem;
}

/* * {
    border: 1px solid red;
} */