.blinking-cursor::after {
    content: "|";
    animation: blink 1s step-end infinite;
  }

.typewritereffect{
  text-align: left;
  padding-left: 5px;
  overflow: hidden;
}
  
  @keyframes blink {
    from,
    to {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  };
  
