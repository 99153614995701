.aboutme {
    height: 100%;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}