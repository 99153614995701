body {
  margin: 0;
  height: 100vmax;
  text-align: center;
  color: whitesmoke;
  background: #000428;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #04101a, #1b1b22);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #04101a, #1b1b22); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-bottom: 100px;
}

.gradientFont {
  background: linear-gradient(349deg, rgba(0,56,255,1) 0%, rgba(7,230,208,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientButton {
  background: linear-gradient(349deg, rgba(0,56,255,1) 0%, rgb(0 88 238) 100%);
  padding: 0.9em;
  border-radius: 10px;
}

.animated-btn {
  margin-top: 10px;
  margin-right: 7px;
  display: inline-block;
  overflow: hidden;
  padding: 10px;
  border: 2px solid aliceblue;
  position: relative; /* important for absolute positioning of button :before element */
  transition: all 0.2s ease-in-out
}

/* .animated-btn:hover {
tran
} */


.animated-btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: aliceblue;
  transition: all 0.2s ease-in-out;
  z-index: -1;
}

.animated-btn:hover:before { /* on hover change before elements */
  width: 100%;
}

.animated-btn:hover {
  background: -webkit-linear-gradient(349deg, rgba(0,56,255,1) 0%, rgba(7,230,208,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}