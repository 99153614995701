.project-flex-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 13rem;
}

/* .description-wrapper{

}

.image-wrapper{

}

.image-project {

}

@media only screen 
and (max-width: 812px)
and (-webkit-min-device-pixel-ratio: 3) {


  } */